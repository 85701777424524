import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
  <Layout>
    <Helmet>
      <title>About Paddy Hegarty</title>
      <meta name="description" content="About Paddy Hegarty" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About Me</h1>
          </header>
          <span className="image main"><img src={pic11} alt="" /></span>
          <p>Previously I had worked at Simply Zesty as a Front End Developer. Before that I worked for 16 years as a freelance website developer. I have gained experience in many different areas of website development and design. I have worked with many clients, each one with varying requirements. I always gained new work through word of mouth, from this I have obtained a good reputation.</p>

          <p>I pay a lot of attention to detail without loosing site of the broader view as the customer requirements always comes first. I have a good ability to convey solutions and communicate effectively. I manage multiple assignments while meeting deadlines consistently. I have built strong relationships with clients and other stakeholders.</p>
          <p><span className="skills-list">React js, Frontend Development, Javascript, jQuery, Polymer, HTML5, CSS3, Rest APIs, WordPress, Adobe Photoshop, Adobe Illustrator, Adobe InDesign, Adobe XD.</span> </p>
          <p>
            After many years working on many different types of projects I have had the pleasure of learning many new skills. As well as the items above that I believe I am at a proficient level, I have also become competent in many other areas including the following:-<br />
            <span className="skills-list">Git, Node, Express, PHP, Linux, Apache, SCSS, NGINX, MongoDB, MySQL, Graphic Design</span>
          </p>
          <p>
            In my spare time its all about family, I also have an interest in photography, art. I also create pencil drawings of portraits and buildings. DIY projects, especially woodwork and welding, is also something I enjoy.
          </p>
          <p>
            Please get in touch, I would love to hear from you.
          </p>
        </div>
      </section>
    </div>

  </Layout>
)

export default Generic